import axios from "axios";
import { getEndpoint } from "../../../helpers/endpoint.helper";

export const fetchGenericReport = async (
    categoryName, reportName, page, format = 'xlsx'
) => {

  let endpoint = getEndpoint('generic-report')
      .replace('{categoryName}', categoryName)
      .replace('{reportName}', reportName)
      .replace('{page}', page)
      .replace('{format}', format);

  try {

    let response = await axios({
      method: "get",
      url: endpoint,
      withCredentials: true,
    });

    // return paginated collection

    return response.data;

  } catch (ex) {

    return {};
  }
}


/**
 * Fetch enrollments
 * @param {string} companyName
 * @param {number} page since collection should be paginated, this value
 * @param {string} criteria Search criteria
 * @param {string} courseCode course code
 * @param {string} status pending or paid
 * @param {string} months months list separated by commas (2021-03,2021-02)
 * indicates which page should be loaded
 * @returns {Promise<{}|any>}
 */
export const fetchEnrollments = async (
  companyName,
  page,
  criteria,
  courseCode,
  status,
  months
) => {

  let endpoint = getEndpoint("enrollments-report").replace("{0}", page);

  // override endpoint for pending installments

  if (status === "pending-installments") {
    endpoint = getEndpoint("pending-installments-report").replace("{0}", page);
  }

  // add query params to endpoint

  let queryParams = [];
  if (criteria) queryParams.push(`criteria=${criteria}`);
  if (courseCode) queryParams.push(`course_code=${courseCode}`);
  if (status) queryParams.push(`status=${status}`);
  if (months) queryParams.push(`months=${months}`);
  if (companyName) queryParams.push(`company_name=${companyName}`);
  endpoint += "?" + queryParams.join("&");

  try {

    let response = await axios({
      method: "get",
      url: endpoint,
      withCredentials: true,
    });

    // return paginated collection

    return response.data;

  } catch (ex) {
    return {};
  }
};

/**
 * Fetch enrollment's additional payments
 * @param companyName
 * @param months
 * @returns {Promise<array>}
 */
export const fetchAdditionalPayments = async (
    companyName, months
) => {
  
  let endpoint;

  // default report endpoint, since
  // we do not need paginated results,
  // use page 0
  endpoint = getEndpoint("additional-payments-report").replace("{0}", 0);

  // add query params to endpoint
  let queryParams = [];
  if (months) queryParams.push(`months=${months}`);
  if (companyName) queryParams.push(`company_name=${companyName}`);
  endpoint += "?" + queryParams.join("&");

  try {
    let response = await axios({
      method: "get",
      url: endpoint,
      withCredentials: true,
    });

    // return collection
    return response.data;
  } catch (ex) {
    return [];
  }
}

/**
 * Submit request to delete payment log
 * @param id payment log
 * @returns {Promise<{boolean}>}
 */
export const deletePaymentLog = async (id) => {
  let endpoint = getEndpoint("payment-log").replace("{0}", id);
  return await submitDeleteRequest(endpoint);
};

/**
 * Submit request to delete enrollment (student-course)
 * @param id student-course
 * @returns {Promise<{boolean}>}
 */
export const deleteEnrollment = async (id) => {
  let endpoint = getEndpoint("enrollment").replace("{0}", id);
  return await submitDeleteRequest(endpoint);
};

/**
 * Submit request to delete payment log or enrollment
 * @param {string} endpoint API endpoint
 * @returns {Promise<{boolean}>}
 */
export const submitDeleteRequest = async (endpoint) => {

  try {
  
    let response = await axios({
      method: "delete",
      url: endpoint,
      withCredentials: true,
    });

    return response.data.success;
  
  } catch (ex) {
    return false;
  }
};

/**
 * 
 */
export const fetchEnrollmentInvoice = async (enrollmentId) => {

  let endpoint = getEndpoint('crud')
      .replace(':collectionName', 'invoices')
      .replace(':id', '');

  endpoint += `?type=by-enrollment&enrollmentId=${enrollmentId}`;    

  try {

    let response = await axios({
      method: 'get',
      url: endpoint,
      withCredentials: true,
    });

    return response.data[0];

  } catch (ex) {
    return false;
  }
}

/**
 * Submit request to delete invoice
 */
export const deleteInvoice = async (id) => {

  let endpoint = getEndpoint('void-invoice').replace(':invoiceId', id);
  try {
    let response = await axios({
      method: 'delete',
      url: endpoint,
      withCredentials: true,
    });

    return response.status === 204;

  } catch (ex) {
    return false;
  }
};


/**
 * Submit request to download invoice PDF file
 */
export const downloadInvoice = async (companyName, id) => {

  let endpoint = getEndpoint('download-invoice')
    .replace(':companyName', companyName)
    .replace(':invoiceId', id);

  try {
    let response = await axios({
      method: 'get',
      url: endpoint,
      withCredentials: true,
      responseType: 'arraybuffer'
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'invoice.pdf';
    link.click();

  } catch (ex) {
    return false;
  }
};
