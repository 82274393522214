import { useEffect, useState } from "react";
import "./ToastNotification.scss";
import { useRecoilState } from 'recoil';
import { toastValuesState } from '../../../store/recoil/recoilState';

export const notificationIcons = Object.freeze({
  check: "check-2",
  error: "error",
});

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ToastNotification = ({
  icon, title, closeCallback, message, isVisible
}) => {

  // CSS classes

  const hiddenClasses = "row panel justify-content-center";
  const visibleClasses = "row panel is-visible justify-content-center";

  // ---------------------------------------
  // set state values

  const [timeoutDelayIsPending, setTimeoutDelayIsPending] = useState(false);
  const [toastValues, setToastValues] = useRecoilState(toastValuesState);

  // ---------------------------------------
  // lifecycle hooks

  // execute when component is UPDATED

  useEffect(() => {
    if (!timeoutDelayIsPending && isVisible) {
      // calls close callback after a delay
      setTimeout(() => {
        // update state
        setTimeoutDelayIsPending(false);

        setToastValues({
          isVisible: false
        })

      }, 5000);

      // update state
      setTimeoutDelayIsPending(true);
    }
  }, [isVisible]);

  // ---------------------------------------
  // return component

  return (
    <div className="toast-notification-component">
      <div className="container container-md">
        <div className={isVisible ? visibleClasses : hiddenClasses}>
          <div className="col-2 d-flex justify-content-center">
            <img
              className="icon"
              src={`imgs/icons/${
                icon ? icon : notificationIcons.error
              }.svg`}
              alt=""
            />
          </div>
          <div className="col-10 position-relative pt-3">
            {/* Notification title */}

            <h3>{title}</h3>

            {/* Notification message */}

            <div
                dangerouslySetInnerHTML={{ __html: message }}
                className="message"></div>

            {/* Close button */}

            <div onClick={closeCallback} className="close button">
              <img src="imgs/icons/close.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastNotification;
